<template>
	<div
		v-if="title || text"
		:id="$vnode.key"
		class="c-block-list-factbox-custom"
	>
		<LongReadTarget
			v-if="title && includeInToc"
			:container-id="$vnode.key"
			:title="title"
		/>

		<div class="bg-secondary py-2xl px-3xl space-y-lg">
			<BaseH2 v-if="title" v-text="title" />
			<div
				v-if="text"
				class="s-rich-text s-rich-text--monotone"
				v-html="text"
			></div>
		</div>
	</div>
</template>

<script>
import LongReadTarget from '~/citi-baseline/components/LongRead/LongReadTarget';

export default {
	name: 'BlockListFactboxCustom',
	components: { LongReadTarget },
	inheritAttrs: false,

	props: {
		title: String,
		includeInToc: Boolean,
		text: String,
	},
};
</script>
